import React from "react"
import { ImageWL } from "@components/Image"
import PropTypes from "prop-types"

const SinglePostImage = ({ image }) => (
  <div className="post-image-container">
    <ImageWL
      className="original"
      src={`${process.env.REACT_APP_BACKEND_URL}/files/${image}`}
      large={`${process.env.REACT_APP_BACKEND_URL}/files/${image}`}
      showOriginal={true}
    />
  </div>
)
SinglePostImage.propTypes = {
  image: PropTypes.string
}
export { SinglePostImage }
