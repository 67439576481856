import { useEffect, useState } from "react"

const useCheckLogin = () => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL
  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const token = localStorage.getItem("login_token")

  useEffect(() => {
    const get = async () => {
      setLoading(true)
      try {
        const response = await fetch(BASE_URL + "/me", {
          method: "GET",
          headers: {
            Authorization: `Token ${token ? JSON.parse(token) : null}`
          }
        })
        if (!response.ok) throw new Error(response.statusText)
        const json = await response.json()
        setLoading(false)
        setData(true)
        setError(null)
        return json
      } catch (error) {
        setError(`${error}. Could not fetch data`)
        setLoading(false)
      }
    }
    get()
  }, [])

  return { data, loading, error }
}

export default useCheckLogin
