import React, { useCallback, useEffect, useState } from 'react'
import { MainLayout } from '../Layouts/MainLayout'
import "./legals.css"
import axios from 'axios'
import DOMPurify from "dompurify"

const ConfidentialPolicy = () => {
  const [page, setPage] = useState("")
  const language = localStorage.getItem("selectedLanguage")
  const [loading, setLoading] = useState(false)
  const sanitizedHtmlContent = DOMPurify.sanitize(page)
  const getPage = useCallback(()=>{
    setLoading(true)
    axios.get(`/page/pdc/${language}`).then(response => {
      setPage(response.data?.content)
      setLoading(false)
    })
  }, [page])
  useEffect(()=>{
    getPage()
  }, [])

  return (
    <MainLayout>
      {!loading && <div  dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}/>
      }
    </MainLayout>
  )
}

export default ConfidentialPolicy
