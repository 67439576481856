import React from "react"
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google"
import axios from "axios"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const GoogleLoginButton = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleSuccess = async (response) => {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/social-login/google/${response.credential}`
    )
    toast.success(t("messages.logged_in"))
    localStorage.setItem("login_token", JSON.stringify(res.data?.token))
    navigate("/")
    // Store token or handle authentication
  }

  const handleError = (error) => {
    console.log("Login Failed:", error)
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
    </GoogleOAuthProvider>
  )
}

export default GoogleLoginButton
